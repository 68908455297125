/* .topia-range-slider {
}

.topia-range-slider::slider-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.topia-range-slider::-ms-slider-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}
*/
/* .topia-range-slider::-moz-range-track {
  visibility: hidden;
  display: none;
  appearance: none;
} */

.topia-range-slider {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

.topia-range-slider::-moz-range-thumb {
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 2.25rem;
  width: 1rem;
}

.topia-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  border-radius: 4px;
  position: relative;
  bottom: -4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 2.25rem;
  width: 1rem;
}
